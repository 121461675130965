import { makeStyles } from '@mui/styles';
import UserIcon from 'apollo-react-icons/User';
import Avatar from 'apollo-react/components/Avatar';
import Box from 'apollo-react/components/Box';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Typography from 'apollo-react/components/Typography';
import moment from 'moment';
import { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { AI_ASSIST_BOT_NAME, BANNER_ERROR_TEXT, ERROR, HIDE_DETAILS, VIEW_DETAILS } from '../../../Constants';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { AiApi } from '../../../Redux/apis/AiApi';
import { RootState } from '../../../Redux/store';
import { getUserName } from '../../../components/Header/utils';
import { ScrollToBottom } from '../../../components/generic/ScrollToBottom';
import { AIFeedbackIconBar } from './AIFeedbackIconBar';
import { DetailsTab } from './DetailsTab';

const useStyles = (historyPanelOpened) => {
    const getWidth = () => {
        if (historyPanelOpened) {
            return '382px'
        } else {
            return '72px'
        }
    }
    return makeStyles({
        chatBox: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '30px',
        },
        converseHelpPage: {
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'
        },
        rightPanel: {
            display: 'flex',
            flexDirection: 'column',
            width: `calc(100% - ${getWidth()}) !important`,
            padding: 10
        },
        chats: {
            height: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                outline: '0.5px slategrey',
                borderRadius: '10px'
            }
        },
        noConvSelected: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: `calc(100% - ${getWidth()}) !important`
        }
    });
};

const USER_NAME = getUserName();

const ChatItem = ({ chat }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [downloadFile] = AiApi.useLazyDownloadFileByPathQuery();
    const [aiImagePath, setAiImagePath] = useState('');

    useEffect(() => {
        if (chat.responseImageFileLocation) {
            const path = chat.responseImageFileLocation;
            const alteredPath = path?.replace(/\\/g, "/");
            const url = encodeURIComponent(alteredPath);
            downloadFile(url).unwrap()
                .then(res => {
                    setAiImagePath(URL.createObjectURL(res));
                });
        }
    }, [chat]);

    const getTimeStamp = (d: string) => {
        let ts;
        const momentDate = moment(d);
        const date = new Date(d);
        const currentDate = new Date();
        const currentMomentDate = moment(currentDate)
        if (!momentDate.isSame(currentMomentDate, 'week')) {
            ts = date.toLocaleDateString([], { day: 'numeric', month: 'short', year: 'numeric' }) + ' ' +
                date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        } else {
            if (momentDate.isSame(currentMomentDate, 'day')) {
                ts = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            } else {
                ts = date.toLocaleDateString([], { weekday: 'short' }) + ' ' +
                    date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            }
        }
        return ts
    }
    return (
        <div id={`chatItem-${chat.id}`} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Avatar style={{ backgroundColor: '#34BECD', color: 'white', width: '24px', height: '24px' }} size="small">
                        <UserIcon />
                    </Avatar>
                    <Typography style={{ fontWeight: 600, fontSize: '16px' }}>{'You'}</Typography>
                    <Typography style={{ fontWeight: 600, fontSize: '16px', marginLeft: '10px' }}>{getTimeStamp(chat.dts)}</Typography>
                </div>
                <div style={{ paddingLeft: '34px' }}>
                    <Typography> {chat.queryText}</Typography>
                </div>
            </div>
            {chat.status === 0 || chat.status === 3 ? <DetailsTab chat={chat} /> :
                <div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Avatar style={{ width: '24px', height: '24px' }} size="small" src={'icon/AiAvatar.png'} />
                        <Typography style={{ fontWeight: 600, fontSize: '16px', fontStyle: 'Proxima Nova' }}>{AI_ASSIST_BOT_NAME}</Typography>
                        {chat.dtsRequestCompleted && <Typography style={{ fontWeight: 600, fontSize: '16px', marginLeft: '10px' }}>{getTimeStamp(chat.dtsRequestCompleted)}</Typography>}
                    </div>
                    {(chat.valueThought?.length !== 0) &&
                        <div style={{ paddingLeft: '34px' }}>
                            <Button
                                sx={{
                                    '.css-plygay-MuiButtonBase-root-MuiButton-root': {
                                        padding: 0
                                    }
                                }}
                                onClick={() => { setShowDetails(!showDetails) }}
                            >
                                {<Typography color={'#595959'} style={{ cursor: 'pointer' }}>{!showDetails ? VIEW_DETAILS : HIDE_DETAILS}</Typography>}
                            </Button>
                        </div>}
                    {showDetails &&
                        <Card tabIndex={0} style={{ backgroundColor: '#E6E6E6', marginLeft: '34px', padding: '10px' }}>
                            <div style={{ whiteSpace: "pre-line" }}>{ReactHtmlParser(chat.valueThought.join('\n'))}</div>
                        </Card>
                    }
                    <div style={{ paddingLeft: '34px', display: 'flex' }}>
                        {(chat.responseImageFileLocation && chat.responseImageFileLocation !== '') &&
                            <div style={{ maxWidth: '40%', maxHeight: '22.5%' }}>
                                <img src={aiImagePath}
                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                    alt={chat.responseImageFileLocation} />
                            </div>
                        }
                        <div style={{ whiteSpace: "pre-line", width: chat.responseImageFileLocation ? '50%' : '100%' }}>{ReactHtmlParser(chat.responseTextString)}</div>
                    </div>
                    <AIFeedbackIconBar chat={chat} />
                </div>}
        </div >
    )
};

const ChatComponent = () => {
    const historyPanelOpened = useSelector((state: RootState) => state.genAi.historyPanelOpened);
    const classes = useStyles(historyPanelOpened)();
    const selectedStudiesForAi = useSelector((state: RootState) => state.selectedStudiesSlice.selectedStudies);
    const { currentConversationContext, scrollToId } = useSelector((state: RootState) => state.genAi);
    const { data: conversationsData, isError: conversationsDataError } = AiApi.useFetchSessionsQuery({ studyId: selectedStudiesForAi[0] });
    const [fetchChats, { data: chatsData, isError: chatsDataError }] = AiApi.useLazyFetchChatsBySessionQuery();
    const dispatch = useDispatch();
    const scrollDelay: number = process.env.REACT_APP_AI_CHAT_SCROLL_DELAY ? Number(process.env.REACT_APP_AI_CHAT_SCROLL_DELAY) : 2000;

    if (chatsDataError || conversationsDataError) {
        dispatch(showBanner({
            variant: ERROR,
            message: BANNER_ERROR_TEXT
        }))
    }

    useEffect(() => {
        if (currentConversationContext.sessionId) {
            fetchChats({
                studyId: selectedStudiesForAi[0],
                sessionId: currentConversationContext.sessionId
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentConversationContext.sessionId]);

    return (
        <div className={classes.chats}>
            <Box className={classes.chatBox}>
                {conversationsData?.map(s => s.sessionId).includes(currentConversationContext.sessionId) && chatsData?.map(c => (<ChatItem key={c.id} chat={c} />))}
                <ScrollToBottom dependencyList={[currentConversationContext.sessionId, scrollToId]} delay={scrollDelay} />
            </Box>
        </div>
    )
};

export default ChatComponent;